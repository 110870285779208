@charset "UTF-8";
@import "ap_preaset";

/****************
**** eTicket ****
****************/
.eTicketWrap {
  width: toRem(815);
  margin: 0 auto;
  padding-top: toRem(32);
  padding-bottom: toRem(80);

  .eLogo img {
    width: toRem(126);
    height: toRem(15.75)
  }
}

.logo img {
  width: toRem(126);
}

.eTicketWrap dl dt {
  width: toRem(163);
  font-size: toRem(13);
  line-height: toRem(13);
}

.eTicketWrap dl dt strong {
  display: block;
}

.eTicketWrap dl dt span {
  color: $ap-text-gray-2;
  font-weight: 400;
}

.eTicketWrap dl dd {
  width: calc(100% - #{toRem(163)});
  font-weight: 500;
}

.mk {
  padding: toRem(2) toRem(4);
  color: $ap-white;
  font-size: toRem(12);
  line-height: toRem(14);
  background: $ap-black;
  border-radius: toRem(4);
}

@media only screen and (max-width: 814px) {
  .eTicketWrap {
    width: 100%;
    padding-left: toRem(25);
    padding-right: toRem(25);
  }
  .eTicketWrap dl dt {
    width: toRem(130);
  }
}

@include rwd('sm') {
  .eTicketWrap dl {
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
  }
  .eTicketWrap dl dt {
    width: auto;
    text-align: left;
  }
  .eTicketWrap dl dd {
    width: auto;
    text-align: right;
  }
}

@include rwd('sm') {
  .iconBox {
    margin-top: toRem(40);
  }
}


/****************
****  Banner Event ****
****************/

.eEventWrap {
  padding: toRem(40) 0px;
}

.eEventBanner {
  height: 94px;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 12px;
  &:nth-child(1){
    background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/thelounge_240530.png');
  }
  &:nth-child(2){
    background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/sinlla_240530.png');
  }
  &:nth-child(3){
    background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/sinsegae_240530.png');
  }
  &:nth-child(4){
    background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/hyundai_240530_2.png');
  }
}


@media only screen and (max-width: 830px) {
  .eEventBanner {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 560px) {
  .eEventBanner {
    width: 100%;
    height: auto;
    aspect-ratio: 4.25 / 1;
    &:nth-child(1) {
      background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/thelounge_m_240530.png');
    }

    &:nth-child(2) {
      background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/sinlla_m_240530.png');
    }

    &:nth-child(3) {
      background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/sinsegae_m_240530.png');
    }

    &:nth-child(4) {
      background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/eticket/hyundai_m_240530.png');
    }
  }
}


